import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import {Router} from '@reach/router';
import {usePageContext} from '@components/Page';
import Meta from '@components/Meta';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import LegalPage from '@components/LegalPage';
import Login from '@components/Login';
import PrivateRoute from '@components/PrivateRoute';
import Signup from '@components/Signup';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  body: Block[];
}

const SubProcessors = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {seoTitle, searchDescription, body} = data.cms.page;
  const {draftailCopy} = getDataFromBlockTypes(body);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Router basepath="/sub-processors">
        <Login path="/login/" component={Login} />
        <PrivateRoute path="/" component={LegalPage} {...draftailCopy} />
        <PrivateRoute path="/signup/" component={Signup} />
      </Router>
    </Fragment>
  );
};

export default SubProcessors;

export const query = graphql`
  fragment SubProcessorFragment on CMS_LegalPage {
    seoTitle
    searchDescription
    body {
      ...headerBodyFragment
    }
  }
  query SubProcessors {
    cms {
      page(url: "/home/sub-processors/") {
        title
        ...SubProcessorFragment
      }
    }
  }
`;
