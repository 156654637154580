import React from 'react';
import {navigate} from 'gatsby';
import {Formik, Form} from 'formik';
import {handleLogin, isLoggedIn} from '@services/auth';
import {ResponsiveContainer} from '@components/layout';
import {ThemeProvider, Colors, styled} from '@styles';
import {Headline} from './typography';
import {StyledInput, Button, StyledLabel} from '@components/Form/ContactForm';

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'loginContainer'
})`
  margin: 8em auto 0;
  height: 47vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2em;
`;

const StyledForm = styled(Form).withConfig({
  componentId: 'loginForm'
})`
  display: flex;
  flex-direction: column;
  margin: 2em 0 0;
  width: 100%;
  align-items: center;

  .wide & {
    align-items: unset;
    width: 50%;
  }
`;

const StyledHeadline = styled(Headline).withConfig({
  componentId: 'loginHeadline'
})`
  text-align: center;

  .wide {
    text-align: left;
  }
`;

const Login = () => {
  if (isLoggedIn()) navigate(`/sub-processors/`);

  return (
    <ThemeProvider
      theme={{
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        minPadding: '6em 2em',
        maxPadding: '10em 0 4em',
      }}
    >
      <Container>
        <StyledHeadline>Enter Password</StyledHeadline>
        <Formik
          initialValues={{password: ''}}
          validate={values => {
            let errors = {};
            if (!values.password) errors.password = 'Required';
            return errors;
          }}
          onSubmit={(values, {setSubmitting}) => {
            handleLogin(values);
            setSubmitting(false);
            navigate('/sub-processors/');
          }}
        >
          {({isSubmitting, errors, touched}) => (
            <StyledForm id="login-form" noValidate>
              <StyledLabel
                htmlFor="password"
                className={errors.password && touched.password ? 'error' : ''}
              >
                Password
                <StyledInput
                  type="password"
                  required
                  id="password"
                  name="password"
                  placeholder={
                    errors.password && touched.password
                      ? 'Password is required'
                      : ''
                  }
                  className={errors.password && touched.password ? 'error' : ''}
                />
              </StyledLabel>
              <Button type="submit" name="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </StyledForm>
          )}
        </Formik>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
