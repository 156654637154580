export const isBrowser = () => typeof window !== 'undefined';

const getUser = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {};

const setUser = user =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user));

export const handleLogin = ({password}: {password: string}): void | false => {
  if (password === '2Usp?') {
    return setUser({username: 'gatsby'});
  }

  return false;
};

export const isLoggedIn = (): boolean => {
  const user = getUser();

  return !!user.username;
};
