import React, {Component} from 'react';
import {navigate} from 'gatsby';
import {isLoggedIn, isBrowser} from '../services/auth';
const PrivateRoute = ({component: Component, location, ...rest}) => {
  if (isBrowser()) {
    if (!isLoggedIn() && location.pathname !== `/sub-processors/login/`) {
      navigate('/sub-processors/login/');
      return null;
    }
    return <Component {...rest} />;
  }
  return null;
};
export default PrivateRoute;
