import React, {useState, useEffect, useRef} from 'react';
import {usePageContext} from '@components/Page';
import {styled} from '@styles';
import {ResponsiveContainer} from '@components/layout';

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'signupContainer'
})`
  display: flex;
  justify-content: center;
  margin: 7em 0 5em;
  &.wide {
    height: ${({height}) => `${height}px`};
    margin: 10em auto 5em;
  }
`;

const Signup = () => {
  usePageContext({shouldInvertMenuColors: true});
  const [iframeHeight, setIframeHeight] = useState(1000);
  const getIframeHeight = () => {
    setIframeHeight(iframe.current.offsetHeight);
  };

  useEffect(() => {
    setTimeout(getIframeHeight, 1000);
  }, []);

  const iframe = useRef(null);

  return (
    <Container height={iframeHeight}>
      <iframe
        ref={iframe}
        title="google form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSefappHvJgegV0hYcWsVCRaHPgvxL_ygNqiaPvbnMBPwiMjTA/viewform?embedded=true"
        width="640"
        height="1200"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
      >
        Loading…
      </iframe>
    </Container>
  );
};

export default Signup;
